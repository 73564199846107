.student-RSA-page {
  display: flex;
  .common-title-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;;
    span.first-child {
      margin-right: 30px;
    }
    .filter-item {
      width: 160px;
      margin-right: 8px;
    }
    .filter-item-date {
      width: 240px;
    }
  }
  &-left {
    width: 340px;
    margin-right: 30px;
    &-content {
      padding: 20px;
      .charge-num {
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5B5D5F;
        margin-top: 15px;
      }
      .small-text-text {
        font-size: 12px;
        margin-bottom: 4px;
        color: #A7AFB9;
      }
      .component-gap-line {
        margin: 40px 0;
      }
      .student-RSA-content-form {
        .ant-form-item {
          margin-bottom: 0;
        }
        .form-textarea {
          height: 65px;
        }
        .ant-picker {
          width: 100%;
        }
      }
      .bottom-opt {
        margin-top: 100px;
        height: 40px;
        text-align: right;
      }
    }
  }
  &-right {
    flex: 1;
    .common-title-text {
      display: flex;
      justify-content: space-between;
    }
  }
}

.form-item-flex .ant-form-item-control-input-content {
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
