@import '~antd/dist/antd.css';
body {
  margin: 0;
  padding: 0;
  font-family:PingFangSC-Regular, PingFang SC;
  padding-left: 80px; /** 为nav留的位置 **/
}
.global-page-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
}
.global-page-content {
  flex: 1;
}
