.teacher-list-page {
  .common-title-text {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 22px;;
    span.first-child {
      margin-right: 30px;
    }
    .filter-item {
      width: 160px;
      margin-right: 8px;
    }
    .filter-item-date {
      width: 240px;
    }
    .button i {
      position: relative;
      top: 1px;
    }
  }
  .teacher-card-container {
    display: flex;
    flex-wrap: wrap;
  }
  .teacher-card {
    width: 256px;
    height: 346px;
    padding: 20px;
    margin-right: 30px;
    margin-bottom: 30px;
    .card-img-wrap {
      position: relative;
      text-align: center;
      margin: 10px auto;
      width: 72px;
      height: 72px;
      border-radius: 72px;
      .card-img {
        width: 72px;
        height: 72px;
        border-radius: 72px;
        background: #D8D8D8;
        border: 1px solid #F1F1F4;
      }
      i {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .card-title1 {
      font-weight: bold;
    }
    .card-title2 {
      font-size: 12px;
    }
    .component-gap-line {
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .card-text {
      margin-top: 10px;
      .iconfont {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background: #2943E2;
        border-radius: 4px;
        color: #fff;
        margin-right: 10px;
      }
      button {
        width: 166px;
        height: 40px;
        background: #E9ECFC;
        border-radius: 4px;
        border: none;
        span {
          color: #2943E2;
        }
      }
    }
  }
}
.trans-to-modal {
  p {
    text-align: center;
    margin-bottom: 4px;
  }
  .ant-modal-body {
    padding: 30px auto 15px;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: none;
  }
}
.ant-drawer-content {
  .admin-index-form-textarea {
    min-height: 120px;
  }
}