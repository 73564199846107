.nav-component {
  width: 80px;
  height: 100vh;
  background: #F9F9FB;
  border-right: 1px solid #DDE2F2;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  img {
    display: block;
  }
  &-logo {
    width: 60px;
    // height: 32px;
    margin: 18px auto 58px;
  }
  &-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &-tab {
    display: flex;
    width: 42px;
    height: 42px;
    border-radius: 12px;
    margin: 0 auto 36px;
    justify-content: center;
    align-items: center;
  }
  &-tab-selected {
    background: #E4E7F8;
  }
}