.admin-index-page {
  .ant-tabs-nav::before {
    border-bottom: 0!important;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
    color: #A7AFB9;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #404244;
  }
  .admin-index-form {
    margin: 0 auto;
    width: 750px;
    .admin-index-form-input1 {

    }
    .admin-index-form-input2 {
      height: 200px;
    }
    .admin-index-form-input3 {
      height: 110px;
    }
  }
  
  .shop-card-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    .add-shop-card {
      width: 353px;
      height: 266px;
      background: #FFFFFF;
      box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.08);
      border-radius: 6px;
      border: 1px solid #F1F1F4;
      margin-right: 30px;
      margin-bottom: 30px;
      .card-img {
        display: block;
        width: 100%;
        height: 150px;
        margin-bottom: 10px;
      }
      .card-title {
        padding: 5px 30px 0 30px;
      }
      .card-text {
        padding: 0 30px;
        margin-top: 15px;
        display: flex;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border-radius: 40px;
          margin-right: 10px;
        }
        p {
          margin-bottom: 4px;
        }
      }
      .add-shop-card-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .add-icon {
          width: 40px;
          height: 40px;
          background: #2943E2;
          font-size: 24px;
          color: #fff;
          border-radius: 40px;
          margin-bottom: 6px;
        }
        .gray-text-desc {
          font-size: 14px;
        }
      }
    }
  }
  .small-text-text {
    font-size: 12px;
    color: #868686;
  }
}