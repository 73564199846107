.student-detail-page {
  .student-desc {
    padding: 30px;
    margin-bottom: 30px;
    position: relative;
    .icon-student-edit {
      color: #2943E2;
      position: absolute;
      right: 20px;
      top: 20px;
    }
    .img {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      margin-right: 10px;
    }
    th {
      text-align: left;
      padding: 0 30px;
      font-size: 14px;
      color: #A7AFB9;
      font-weight: normal;
    }
    tbody {
      td {
        min-width: 150px;
        padding: 0 30px;
        text-align: justify;
        border-right: 1px solid #A7AFB9;
        &:last-child {
          border-right: none;
        }
      }
    }
    .component-gap-line {
      margin-bottom: 30px;
      margin-top: 55px;
    }
    .student-cards {
      display: flex;
      // justify-content: space-between;
      .student-card {
        width: 333px;
        height: 140px;
        background: #F9FAFF;
        border-radius: 6px;
        padding: 35px 0 0 30px;
        margin-right: 30px;
        .common-title-text {
          font-size: 30px;
          font-weight: bolder;
        }
        i {
          color: #2943E2;
        }
        p {
          margin-bottom: 4px;
          font-size: 12px;
        }
      }
    }
  }
  .click-cards {
    display: flex;
    justify-content: space-between;
  }
  .click-card {
    height: 140px;
    position: relative;
    width: 23%;
    padding: 30px;
    .common-title-text {
      font-size: 24px;
      color: #000000;
      font-weight: bolder;
    }
    img {
      width: 105px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  
  }
}