@font-face {
  font-family: "iconfont"; /* Project id 2598266 */
  src: url('iconfont.woff2?t=1624158232583') format('woff2'),
       url('iconfont.woff?t=1624158232583') format('woff'),
       url('iconfont.ttf?t=1624158232583') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-edit:before {
  content: "\e69e";
}

.icon-shuaxin:before {
  content: "\e67f";
}

.icon-at-solid:before {
  content: "\e649";
}

.icon-cart:before {
  content: "\e64a";
}

.icon-add:before {
  content: "\e64b";
}

.icon-auth:before {
  content: "\e64c";
}

.icon-caifu:before {
  content: "\e64d";
}

.icon-dui:before {
  content: "\e64e";
}

.icon-feiji:before {
  content: "\e64f";
}

.icon-code:before {
  content: "\e650";
}

.icon-eye-open:before {
  content: "\e651";
}

.icon-close:before {
  content: "\e652";
}

.icon-cuowu1:before {
  content: "\e653";
}

.icon-duigou:before {
  content: "\e654";
}

.icon-erweima:before {
  content: "\e655";
}

.icon-eye:before {
  content: "\e656";
}

.icon-Group-:before {
  content: "\e657";
}

.icon-download:before {
  content: "\e658";
}

.icon-gift2e:before {
  content: "\e659";
}

.icon-eye-close:before {
  content: "\e65a";
}

.icon-gouwuche:before {
  content: "\e65b";
}

.icon-info:before {
  content: "\e65c";
}

.icon-ictotop:before {
  content: "\e65d";
}

.icon-jt-block-down:before {
  content: "\e65e";
}

.icon-jiantou-copy-copy:before {
  content: "\e65f";
}

.icon-huifu:before {
  content: "\e660";
}

.icon-kuaixun:before {
  content: "\e661";
}

.icon-jiesuo:before {
  content: "\e662";
}

.icon-icon_tiwen:before {
  content: "\e663";
}

.icon-lingdang:before {
  content: "\e664";
}

.icon-laba:before {
  content: "\e665";
}

.icon-lock:before {
  content: "\e666";
}

.icon-love:before {
  content: "\e667";
}

.icon-message:before {
  content: "\e668";
}

.icon-jifen:before {
  content: "\e669";
}

.icon-post-it:before {
  content: "\e66a";
}

.icon-pinglun:before {
  content: "\e66b";
}

.icon-pinglun1:before {
  content: "\e66c";
}

.icon-kefu:before {
  content: "\e66d";
}

.icon-qiami:before {
  content: "\e66e";
}

.icon-search:before {
  content: "\e66f";
}

.icon-shoucang:before {
  content: "\e670";
}

.icon-smile1:before {
  content: "\e671";
}

.icon-shezhi1:before {
  content: "\e672";
}

.icon-tuichu:before {
  content: "\e673";
}

.icon-time1:before {
  content: "\e674";
}

.icon-task-line:before {
  content: "\e675";
}

.icon-sub:before {
  content: "\e676";
}

.icon-user:before {
  content: "\e677";
}

.icon-wode:before {
  content: "\e678";
}

.icon-wenjian:before {
  content: "\e679";
}

.icon-time:before {
  content: "\e67a";
}

.icon-tianchongxing-:before {
  content: "\e67b";
}

.icon-write:before {
  content: "\e67c";
}

.icon-zhuanti:before {
  content: "\e67d";
}

.icon-xinaixin:before {
  content: "\e67e";
}

