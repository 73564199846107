.lesson-list-page {
  .common-title-text {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 22px;;
    span.first-child {
      margin-right: 30px;
    }
    .filter-item {
      width: 160px;
      margin-right: 8px;
    }
    .filter-item-date {
      width: 240px;
    }
    .lesson-detail-page-right-yuyue-btn {
      color: #5B5D5F!important;
      border: 1px solid #F1F1F4!important;
      margin-right: 10px!important;
    }
  }
}