
.ant-drawer-body {
  padding-bottom: 80px!important;
  .shopImg-wrap {
    height: 150px;
    overflow-y: hidden;
  }
  .shopImg {
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }
  .headImg {
    width: 96px;
    height: 96px;
    border-radius: 96px;
    background: #D8D8D8;
    border: 1px solid #F1F1F4;
    margin: 0 auto 30px auto;
  }
  .shop-check-text {
    font-size: 12px;
    color: #404244;
    height: 34px;
    align-items: center;
    display: flex;
    i {
      margin-right: 8px;
    }
  }
  .ant-upload {
    .add-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      height: 20px;
      line-height: 18px;  
      background: #2943E2;
      font-size: 16px;
      color: #fff;
      border-radius: 20px;
    }
    .ant-upload-placeholder-img {
      // width: 100%;
      height: 100%;
    }
  }
  .ant-upload-picture-card-wrapper.avatar-uploader {
    position: relative;
    margin-bottom: 15px;
    .icon-upload-img {
      position: absolute;
      bottom: 10px;
      right: 10px;
      color: #fff;
      width: 22px;
      height: 22px;
      border-radius: 22px;
      background: rgba(0,0,0,0.7);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
      }
    }
    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
      // height: auto!important;
      min-height: 120px;
      align-items: center;
      display: flex;
      margin-bottom: 0;
      .placeholder-img-wrap {
        width: 100%;
        height: 150px;
        overflow: hidden;
        .ant-upload-placeholder-img {
          width: 100%;
          height: 150px;
        }
      }
    }
  }
  .avatar-uploader {
    .ant-upload.ant-upload-select-picture-card {
      height: 150px;
    }
  }
  .headImg-uploader {
    display: block;
    width: 96px;
    height: 96px;
    background: #F9F9FB;
    border: 1px dashed #CED2D5;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto 36px;
    .add-icon {
      position: relative;
      // top: -10px;
    }
    .ant-upload-select-picture-card {
      height: 100%!important;
      min-height: auto!important;
    }
    .ant-upload-placeholder-img.headImg-uploader-placeholder-img {
      width: 96px;
      height: 96px;
      border-radius: 100%;
    }
  }
  .form-input {
    align-items: center;
    .ant-input, .ant-select {
      flex: 1;
      display: flex;
    }
    .form-input-label {
      font-size: 12px;
      color: #868686;
      width: 75px;
      text-align: left;
    }
    &-checkbox {
      display: inline-block!important;
      vertical-align: middle;
      margin-right: 6px;
      width: 14px;
    }
  }
  .ant-picker-range {
    flex: 1;
  }
  .add-new-classroom {
    height: 32px;
    line-height: 32px;
    margin-left: 75px;
    padding-left: 10px;
    border-radius: 2px;
    border: 1px dashed #CED2D5;
    display: flex;
    margin-top: 12px;
    input {
      flex: 1;
      margin-right: 10px;
    }
  }
  .add-new-classroom1 {
    display: block;
  }
  .small-text-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .component-gap-line {
    margin: 20px 0;
  }
  .add-new-input {
    border: none;
    padding-left: 0;
  }
  .draw-footer {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 380px;
    text-align: right;
    background: #fff;
    padding-top: 10px;
    border-top: 1px solid #CED2D5;
    .ant-btn {
      margin-right: 30px;
    }
    .ant-form-item {
      margin-bottom: 10px;
    }
  }
}