.header-component {
  height: 60px;
  line-height: 60px;
  flex: 1;
  border-bottom: 1px solid #F1F1F4;
  padding-right: 50px;
  align-items: center;
  font-size: 14px;
  text-align: right;
  &-user {
    display: inline-block;
  }
  img {
    width: 30px;
    height: 30px;
    border: 1px solid #DDE2F2;
    border-radius: 30px;
    position: relative;
    // top: 10px;
    margin-right: 10px;
  }
  &-quit {
    display: inline-block;
    margin-left: 50px;
  }
}