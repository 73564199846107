.toastInit {
  display: none !important;
}

.toastBox {
  position: fixed;
  left: 0;
  top: -1rem;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;

  .toastMask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    z-index: 999;
  }

  .toastContent {
    max-width: 60%;
    color: white;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 6px;
    font-size: 15px;
    opacity: 1;
    transition-property: opacity, transform;
    transition-duration: 0.2s;
    transform: translate(0, -30%);

    .toastInnerBox {
      .toastInnerText {
        font-size: 15px;
        width: 100%;
        text-align: center;
      }
    }

    .toastLoadingImg {
      padding: .6rem;
      text-align: center;
      img {
        width: 50px;
        height: 50px;
      }
    }
    .toastInnerImg {
      width: 50px;
      height: 50px;
      margin: 10px auto;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .toastEnter {
    opacity: 0;
    transform: translate(0, -30%);
  }
}

.toastOut {
  display: none !important;
}

@keyframes toastLoading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg)
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn)
  }
}