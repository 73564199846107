.teacher-detail-page {
  .teacher-desc {
    padding: 30px;
    margin-bottom: 30px;
    justify-content: flex-start;
    img {
      width: 72px;
      height: 72px;
      border-radius: 100%;
      margin-right: 10px;
    }
    th {
      text-align: left;
      padding: 0 30px;
      font-weight: normal;
      color: #A7AFB9;
    }
    tbody {
      td {
        min-width: 150px;
        padding: 0 30px;
        text-align: justify;
        border-right: 1px solid #A7AFB9;
        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .common-title-text {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 22px;;
    span.first-child {
      margin-right: 30px;
    }
    .filter-item {
      width: 160px;
      margin-right: 8px;
    }
    .filter-item-date {
      width: 240px;
    }
  }
}