.teacher-lesson-page {
  display: flex;
  .common-title-text {
    margin-bottom: 30px;
  }
  &-left {
    width: 340px;
    margin-right: 30px;
    &-content {
        padding: 20px;
        .small-text-text {
            font-size: 12px;
            margin-bottom: 10px;
        }
        .form-input {
            align-items: center;
            .ant-input, .ant-select {
                flex: 1;
                display: flex;
            }
            &-checkbox {
                display: inline-block!important;
                vertical-align: middle;
                margin-right: 6px;
                width: 14px;
            }
        }
    }
  }
  &-right {
    flex: 1;
    .common-title-text {
        display: flex;
        justify-content: space-between;
    }
    .lesson-detail-right-table-headimg {
    width: 30px;
    height: 30px;
    border: 1px solid #EDEDED;
    border-radius: 30px;
    }
  }
}