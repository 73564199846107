.lesson-dashboard-page {
    padding: 0 40px;
    min-width: 1200px;
    .iconfont {
        display: inline-block;
    }
    .trans180 {
        transform: rotate(180deg);
    }
    &-header {
        display: flex;
        height: 80px;
        align-items: center;
        justify-content: space-between;
        &-left {
            display: flex;
            height: 80px;
            align-items: center;
        }
        &-title {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #404244;
            margin-right: 30px;
        }
        .ant-select-arrow {
            top: 45%;
        }
        &-item {
            display: inline-block;
            width: 160px;
            height: 36px;
            border-radius: 6px;
            border: 1px solid #F1F1F4;
            display: flex;
            align-items: center;
            margin-right: 20px!important;
            .ant-select-selector {
                border: none!important;
            }
            .change-icon {
                width: 36px;
                height: 36px;
                text-align: center;
                line-height: 36px;
            }
            .change-icon-radius {
                // background: #2943E2;
                border-radius: 6px 0px 0px 6px;
            }
            &-time {
                display: inline-block;
                width: 88px;
                text-align: center;
            }
        }
        span.lesson-dashboard-page-header-item {
            padding: 0 11px;
        }
    }
    .fc .fc-toolbar.fc-header-toolbar {
        margin-bottom: 0;
    }
    .fc .fc-timegrid-col.fc-day-today {
        background-color: transparent;
    }
    .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
        height: 710px;
        thead {
            .calendar-header, .fc-timegrid-axis:first-child {
                height: 59px;
                line-height: 59px;
                background: #F9F9FB;
                a {
                    font-size: 16px;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-weight: 500;
                    color: #A7AFB9;
                }
            }
        }
        .calendar-event-block {
            box-shadow: none;
            border: none;
            border-radius: 6px;
            // position: relative;
            // top: 8px;
            .calendar-event-block-content {
                padding: 8px;
                font-size: 14px;
                color: #fff;
                &-title {
                    display: flex;
                    justify-content: space-between;
                    line-height: 16px;
                    span:last-child {
                        font-size: 12px;
                    }
                }
                &-desc {
                    font-size: 12px;
                    line-height: 16px;
                    // margin-bottom: 4px;
                }
                &-time {
                    font-weight: bold;
                    line-height: 16px;
                }
            }
        }
        .calendar-event-blue {
            background: #2943E2;
        }
        .calendar-event-green {
            background: #01B075;
        }
        .calendar-event-orange {
            background: #F96E53;
        }
        .calendar-event-gray {
            background: #D0D4D5;
        }
        .fc-scroller {
            overflow: inherit;
        }
    }

    // 将表格统一下移15px,从而边框线能和整点对其
    // 课表单元格边框样式设置
    .fc-timegrid-slots {
        td.fc-timegrid-slot-lane {
            // position: relative;
            // top: 12px;
        }
        .fc-timegrid-slot-label {
            border: none;
        }
        .fc-timegrid-slot-lane {
            border: none;
        }
        .fc-timegrid-slot-lane[data-time="00:00:00"],
        .fc-timegrid-slot-lane[data-time="01:00:00"],
        .fc-timegrid-slot-lane[data-time="02:00:00"],
        .fc-timegrid-slot-lane[data-time="03:00:00"],
        .fc-timegrid-slot-lane[data-time="04:00:00"],
        .fc-timegrid-slot-lane[data-time="05:00:00"],
        .fc-timegrid-slot-lane[data-time="06:00:00"],
        .fc-timegrid-slot-lane[data-time="07:00:00"],
        .fc-timegrid-slot-lane[data-time="08:00:00"],
        .fc-timegrid-slot-lane[data-time="09:00:00"],
        .fc-timegrid-slot-lane[data-time="10:00:00"],
        .fc-timegrid-slot-lane[data-time="11:00:00"],
        .fc-timegrid-slot-lane[data-time="12:00:00"],
        .fc-timegrid-slot-lane[data-time="13:00:00"],
        .fc-timegrid-slot-lane[data-time="14:00:00"],
        .fc-timegrid-slot-lane[data-time="15:00:00"],
        .fc-timegrid-slot-lane[data-time="16:00:00"],
        .fc-timegrid-slot-lane[data-time="17:00:00"],
        .fc-timegrid-slot-lane[data-time="18:00:00"],
        .fc-timegrid-slot-lane[data-time="19:00:00"],
        .fc-timegrid-slot-lane[data-time="20:00:00"],
        .fc-timegrid-slot-lane[data-time="21:00:00"],
        .fc-timegrid-slot-lane[data-time="22:00:00"],
        .fc-timegrid-slot-lane[data-time="23:00:00"],
        .fc-timegrid-slot-lane[data-time="24:00:00"] {
            border-top: 1px solid #F1F1F4;
        }
        .fc-timegrid-slot-lane[data-time="10:00:00"] {
            display: block;
        }
    }
    // 课表单元格边框样式设置

    .fc-timegrid-more-link.fc-more-link {
        top: auto!important;
        // background: transparent;
        color: #fff;
    }
    .fc-theme-standard .fc-popover {
        border: none;
        box-shadow: 0px 2px 18px 0px rgba(5, 4, 4, 0.08);
        border-radius: 6px;
    }
    .calendar-gap {
        height: 50px;
    }
    .fc-col-header-cell, .fc-timegrid-axis {
        border: none;
    }
}