.lesson-detail-page {
    display: flex;
    .common-title-text {
        margin-bottom: 30px;
    }
    &-left {
        width: 340px;
        margin-right: 30px;
        &-content {
            padding: 20px;
            .small-text-text {
                font-size: 12px;
                margin-bottom: 10px;
            }
            .bottom-opt {
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .form-input {
                    align-items: center;
                    .ant-input, .ant-select {
                        flex: 1;
                        display: flex;
                    }
                    &-checkbox {
                        display: inline-block!important;
                        vertical-align: middle;
                        margin-right: 6px;
                        width: 14px;
                    }
                }
                .icon-edit-opt {
                    width: 40px;
                    height: 40px;
                    background: #2943E2;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                    line-height: 40px;
                }
            }
        }
    }
    &-right {
        flex: 1;
        .common-title-text {
            display: flex;
            justify-content: space-between;
        }
        &-yuyue-btn {
            width: 104px!important;
            color: #fff!important;
            border: none!important;
            .iconfont {
                margin-right: 4px;
                position: relative;
                top: 1px;
            }
        }
        .lesson-detail-right-table-headimg {
            width: 30px;
            height: 30px;
            border: 1px solid #EDEDED;
            border-radius: 30px;
        }
        .lesson-detail-right-table-opt {
            width: 20px;
            height: 20px;
            background: #D0D4D5;
            border-radius: 20px;
            color: #fff;
            text-align: center;
            line-height: 20px;
            background: #2943E2;
        }
    }
}
.cancel-course-order-modal {
    .ant-modal-content {
        width: 350px;
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        margin: 0 auto;
    }
    .ant-modal-body, .ant-modal-footer {
        text-align: center;
        border-top: none;
    }
    .ant-modal-body {
        position: relative;
        top: 25px;
    }
}
.add-order-user-option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p {
        margin-bottom: 0;
        margin-right: 10px;
    }
    img {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin-right: 8px;
    }
}