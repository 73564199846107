.login-page {
  width: calc(100% + 80px);
  height: 100%;
  min-height: 100vh;
  background: url('../assets/img/loginBg.jpg') center center no-repeat;
  background-size: cover;
  margin-left: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-content {
    width: 800px;
    height: 500px;
    background: #FFFFFF;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    &-img {
      width: 500px;
      height: 100%;
    }
    &-form {
      display: inline-block;
      width: 300px;
      height: 100%;
      vertical-align: top;
      padding: 160px 60px 0;
      .ant-form-item-explain-error {
        font-size: 12px;
        font-weight: 400;
        color: #F96E53;
      }
      button {
        width: 180px;
        height: 34px;
        background: #2943E2;
        border-radius: 6px;
        font-size: 14px;
        color: #fff;
      }
    }
  }
}