.shop-index-page {
  .title {
    margin-bottom: 20px;
  }
  .shop-card-container {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
  }
  .add-shop-card {
    width: 353px;
    height: 266px;
    background: #FFFFFF;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    border: 1px solid #F1F1F4;
    margin-right: 30px;
    margin-bottom: 30px;
    overflow: hidden;
    .card-img {
      display: block;
      width: 100%;
      height: 150px;
      margin-bottom: 10px;
    }
    .card-title {
      padding: 0 10px;
      height: 50px;
    }
    .card-text {
      padding: 0 10px;
    }
    .add-shop-card-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .add-icon {
        width: 40px;
        height: 40px;
        background: #2943E2;
        font-size: 24px;
        color: #fff;
        border-radius: 40px;
        margin-bottom: 8px;
      }
      .gray-text-desc {
        font-size: 14px;
      }
    }
  }
}