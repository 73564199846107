.common-page-container {
  padding: 20px 40px;
}

.cursor {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.display-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inline-block {
  display: inline-block;
}

.common-title-text {
  font-size: 22px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #404244;
}

.small-text-text {
  font-size: 16px;
  font-weight: 400;
  color: #404244;
  line-height: 24px;
}
.gray-text-desc {
  font-size: 12px;
  color: #A2AABE;
  line-height: 20px;
}

.box-shadow-card {
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

button.ant-btn {
  width: 80px;
  height: 34px;
  background: #FFFFFF;
  color: #2943E2;
  border-radius: 4px;
  border: 1px solid #2943E2;
}
button.ant-btn-primary {
  background: #2943E2;
  color: #fff;
}

i.icon-eye {
  color: #2943E2;
}
i.icon-write {
  font-weight: bold;
}
i.icon-write-png {
  margin-right: 12px;
  padding-right: 12px;
  border-right:  1px solid #A7AFB9;
  img {
    width: 14px;
  }
}
i.icon-write1 {
  img {
    width: 14px;
  }
}

.ant-table.ant-table-bordered > .ant-table-container {
  border: none;
}
.ant-table table {
  border-radius: 10px;
  border: 1px solid #F1F1F4;
  overflow: hidden;
  .ant-table-thead > tr > th {
    background: #fff;
    color: #A7AFB9;
  }
  td, th {
    border: none;
    border-right: none!important;
  }
  tr:nth-child(odd) {
    background: #F9FAFF;
  }
}
.ant-table-placeholder td {
  border-top: 1px solid #F1F1F4!important;
}
.ant-table-gray-row {
  color: #A7AFB9;
}
.ant-form-item-explain, .ant-form-item-extra {
  color: #F96E53;
  font-size: 12px;
}

.red-text {
  color: #F96E53;
}

.ant-upload {
  width: 100%!important;
}

.ant-select-selector {
  width: 100%;
}

.ant-drawer-content-wrapper {
  min-width: 350px!important;
}

.form-input-checkbox {
  position: relative;
  top: -1px;
}
input.unEditable-checkbox-input[type=checkbox]::after {
  position: absolute;
  top: 0;
  color: #000;
  width: 100%;
  height: 100%;
  display: block;
  visibility: visible;
  padding-left: 0px;
  text-align: center;
  content: ' ';
  border-radius: 3px;
  text-align: center;
  line-height: 13px;
}
input.unEditable-checkbox-input[type=checkbox]:checked::after {
  content: "✓";
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  background-color: #404244;
}