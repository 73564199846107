.student-list-page {
  .common-title-text {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 22px;;
    span.first-child {
      margin-right: 30px;
    }
    .filter-item {
      width: 160px;
      margin-right: 8px;
    }
    .filter-item-date {
      width: 240px;
    }
  }
  .student-list-right-table-headimg {
    width: 30px;
    height: 30px;
    border: 1px solid #EDEDED;
    border-radius: 30px;
  }
}
.drawer-detail-img {
  width: 100%;
  height: 120px;
  display: block;
  margin-bottom: 15px;
}

// 创建学生弹窗相关
.drawer-choose-account-type {
  .choose-create-type {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
    .choose-create-type-content {
      text-align: center;
      &:first-child {
        margin-bottom: 50px;
      }
      .create-add-btn {
        display: flex;
        width: 96px;
        height: 96px;
        background-color: rgba(41, 67, 226, .1);
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        span {
          width: 24px;
          height: 24px;
          line-height: 24px;
          background: #2943E2;
          border-radius: 100%;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}