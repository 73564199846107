.component-justify-text {
  display: flex;
  height: 34px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #404244;
  span:first-child {
    color: #868686;
  }
}